<template>
  <div>
    <v-container>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูล รายงานตัวแสดงเจตจำนงเลือกสถานศึกษา ตำแหน่งครูผู้ช่วย"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" lg="6" class="text-right">
              <v-btn
                large
                right
                depressed
                color="primary"
                @click.native="periodassteachAdd()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="periodassteachs"
          :search="search"
        >
          <template v-slot:item.periodAssTeachStart="{ item }">
            {{
              item.periodAssTeachStart
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>
          <template v-slot:item.periodAssTeachStop="{ item }">
            {{
              item.periodAssTeachStop
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>
          <template v-slot:item.periodassteach_cal_end="{ item }">
            {{
              item.periodassteach_cal_end
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>
          <template v-slot:item.periodAssTeachEnable="{ item }">
            <v-icon
              large
              color="green darken-2"
              v-if="item.periodAssTeachEnable === '1'"
              >mdi-alarm-light</v-icon
            >
            <v-icon v-if="item.periodAssTeachEnable === '0'"
              >mdi-alarm-light-outline</v-icon
            >
          </template>

          <template v-slot:item.periodAssTeachEnablePro="{ item }">
            <v-icon
              large
              color="green darken-2"
              v-if="item.periodAssTeachEnablePro === '1'"
              >mdi-alarm-light</v-icon
            >
            <v-icon v-if="item.periodAssTeachEnablePro === '0'"
              >mdi-alarm-light-outline</v-icon
            >
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              large
              color="yellow"
              @click.stop="periodassteachEdit(item.periodAssTeachID)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon
              color="red"
              large
              @click.stop="periodassteachDelete(item.periodAssTeachID)"
            >
              mdi-delete
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addperiodassteachdialog  -->
      <v-layout row justify-center>
        <v-dialog v-model="addperiodassteachdialog" persistent max-width="50%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลปี"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addperiodassteachform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md6>
                      <v-select
                        v-model="addperiodassteach.periodAssTeachYear"
                        :items="periodassteachselect"
                        item-text="text"
                        item-value="value"
                        label="เลือกปีการศึกษา"
                        single-line
                      >
                      </v-select>
                      <v-spacer></v-spacer>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        type="number"
                        label="ครั้งที่"
                        v-model="addperiodassteach.periodAssTeachTimes"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        type="date"
                        label="วันที่เริ่ม"
                        v-model="addperiodassteach.periodAssTeachStart"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        type="date"
                        label="วันที่สิ้นสุด"
                        v-model="addperiodassteach.periodAssTeachStop"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-switch
                        v-model="addperiodassteach.periodAssTeachEnable"
                        label="เปิดระบบ"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-switch
                        v-model="addperiodassteach.periodAssTeachEnablePro"
                        label="เปิดใช้ระบบประมวลผล"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="addperiodassteachdialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addperiodassteachSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deleteperiodassteachdialog -->
      <v-layout>
        <v-dialog
          v-model="deleteperiodassteachdialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลปี"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deleteperiodassteachform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบรายการ
                          {{ editperiodassteach.periodAssTeachID }} :
                          {{ editperiodassteach.periodassteach_year }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deleteperiodassteachdialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deleteperiodassteachSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editperiodassteachdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editperiodassteachdialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลปี"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editperiodassteachform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md6>
                      <v-select
                        v-model="editperiodassteach.periodAssTeachYear"
                        :items="periodassteachselect"
                        item-text="text"
                        item-value="value"
                        label="เลือกปีการศึกษา"
                        single-line
                      >
                      </v-select>
                      <v-spacer></v-spacer>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        type="number"
                        label="ครั้งที่"
                        v-model="editperiodassteach.periodAssTeachTimes"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        type="date"
                        label="วันที่เริ่ม"
                        v-model="editperiodassteach.periodAssTeachStart"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        type="date"
                        label="วันที่สิ้นสุด"
                        v-model="editperiodassteach.periodAssTeachStop"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-switch
                        v-model="editperiodassteach.periodAssTeachEnable"
                        label="เปิดระบบ"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-switch
                        v-model="editperiodassteach.periodAssTeachEnablePro"
                        label="เปิดใช้ระบบประมวลผล"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editperiodassteachdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editperiodassteachSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        multi-line
        vertical
        top
        auto-height
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <br />
        {{ snackbar.text }}
        <v-btn dark @click="snackbar.show = false">
          Close
        </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import VueMoment from "vue-moment";
import moment from "moment-timezone";
export default {
  name: "periodassteach",
  data() {
    return {
      VueMoment,
      moment,
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      addperiodassteachdialog: false,
      editperiodassteachdialog: false,
      deleteperiodassteachdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      periodassteach_types: [
        { text: "สายงานบริหารสถานศึกษา", value: "manage" },
        { text: "สายการสอนและสายสนับสนุนการสอน", value: "teacher" }
      ],
      periodassteachs: [],
      addperiodassteach: {},
      editperiodassteach: {},
      search: "",
      pagination: {},
      headers: [
        { text: "ครั้งที่", align: "center", value: "periodAssTeachTimes" },
        { text: "ปี", align: "center", value: "periodAssTeachYear" },
        { text: "เริ่มวันที่", align: "center", value: "periodAssTeachStart" },
        { text: "สิ้นสุดวันที่", align: "center", value: "periodAssTeachStop" },
        { text: "สถานะ", align: "center", value: "periodAssTeachEnable" },
        { text: "ประมวลผล", align: "center", value: "periodAssTeachEnablePro" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      periodassteachselect: [2566, 2567, 2568, 2569, 2570],
      data_syslog: {}
    };
  },
  async mounted() {
    this.periodassteachQueryAll();
  },
  methods: {
    async periodassteachQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("periodassteach.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.periodassteachs = result.data;
    },

    async periodassteachAdd() {
      this.addperiodassteach = {};
      this.addperiodassteach.periodassteach_year = new Date()
        .getFullYear()
        .toString();
      this.addperiodassteach.periodAssTeachStart = new Date()
        .toISOString()
        .substr(0, 10);
      this.addperiodassteach.periodAssTeachStop = new Date()
        .toISOString()
        .substr(0, 10);
      this.addperiodassteach.periodAssTeachEnable = false;
      this.addperiodassteach.periodAssTeachEnablePro = false;
      this.adddialog = true;
      this.addperiodassteachdialog = true;
    },

    async linenotify(topics, details, datetime_close) {
      let data = {
        ApiKey: this.ApiKey,
        topics: topics,
        details: details,
        datetime_close: datetime_close
      };
      let result = await this.$http.post("linenotify_college.php", data);
      
    },

    async addperiodassteachSubmit() {
      if (this.addperiodassteach.periodAssTeachEnable == true) {
        this.addperiodassteach.periodAssTeachEnable = "1";
        let timesy =
          this.addperiodassteach.periodAssTeachTimes +
          "/" +
          this.addperiodassteach.periodAssTeachYear;  
          let details = "รายงานตัวครูผู้ช่วย"
        await this.linenotify(
          timesy,
          details,
          this.addperiodassteach.periodAssTeachStop
        );
      } else {
        this.addperiodassteach.periodAssTeachEnable = "0";
      }
      if (this.addperiodassteach.periodAssTeachEnablePro == true) {
        this.addperiodassteach.periodAssTeachEnablePro = "1";
      } else {
        this.addperiodassteach.periodAssTeachEnablePro = "0";
      }

      this.addperiodassteach.ApiKey = this.ApiKey;
      let result = await this.$http.post(
        "periodassteach.insert.php",
        this.addperiodassteach
      );
      if (result.data.status == true) {
        this.periodassteach = result.data;
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.periodassteachQueryAll();
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.data_syslog.ApiKey = this.ApiKey;
        this.data_syslog.user_account = userSession.user_name;
        this.data_syslog.event_log = "insert";
        this.data_syslog.page_log = "periodassteach";
        this.data_syslog.table_log = "periodassteach";
        this.data_syslog.date_times = this.date_today_log;
        await this.$http.post("data_syslog.insert.php", this.data_syslog);
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
        this.periodassteachQueryAll();
      }
      this.addperiodassteachdialog = false;
    },

    async periodassteachEdit(periodAssTeachID) {
      let result = await this.$http.post("periodassteach.php", {
        ApiKey: this.ApiKey,
        periodAssTeachID: periodAssTeachID
      });
      this.editperiodassteach = result.data;
      if (this.editperiodassteach.periodAssTeachEnable == 1)
        this.editperiodassteach.periodAssTeachEnable = true;
      else this.editperiodassteach.periodAssTeachEnable = false;
      if (this.editperiodassteach.periodAssTeachEnablePro == true)
        this.editperiodassteach.periodAssTeachEnablePro = true;
      else this.editperiodassteach.periodAssTeachEnablePro = false;
      this.editperiodassteachdialog = true;
    },
    async editperiodassteachSubmit() { 

      if (this.editperiodassteach.periodAssTeachEnablePro == true)
        this.editperiodassteach.periodAssTeachEnablePro = "1";
      else this.editperiodassteach.periodAssTeachEnablePro = "0";
      this.editperiodassteach.ApiKey = this.ApiKey;
      let result = await this.$http.post(
        "periodassteach.update.php",
        this.editperiodassteach
      );
      if (result.data.status == true) {
        this.periodassteach = result.data;
        Swal.fire({
          icon: "success",
          title: "แก้ไขข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.periodassteachQueryAll();
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.data_syslog.ApiKey = this.ApiKey;
        this.data_syslog.user_account = userSession.user_name;
        this.data_syslog.event_log = "update";
        this.data_syslog.page_log = "periodassteach";
        this.data_syslog.table_log = "periodassteach";
        this.data_syslog.date_times = this.date_today_log;
        await this.$http.post("data_syslog.insert.php", this.data_syslog);
      } else {
        Swal.fire({
          icon: "warning",
          title: "แก้ไขข้อมูลผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.editperiodassteachdialog = false;
    },

    async periodassteachDelete(periodAssTeachID) {
      let result = await this.$http.post("periodassteach.php", {
        ApiKey: this.ApiKey,
        periodAssTeachID: periodAssTeachID
      });
      this.editperiodassteach = result.data;
      this.deleteperiodassteachdialog = true;
    },

    async deleteperiodassteachSubmit() {
      if (this.$refs.deleteperiodassteachform.validate()) {
        this.editperiodassteach.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "periodassteach.delete.php",
          this.editperiodassteach
        );

        if (result.data.status == true) {
          this.periodassteach = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.periodassteachQueryAll();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "delete";
          this.data_syslog.page_log = "periodassteach";
          this.data_syslog.table_log = "periodassteach";
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการลบผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deleteperiodassteachdialog = false;
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
